.latmpoc-grid-container {
  display: grid;
  grid-template-columns: 160px 75px 75px 75px 75px;
  column-gap: 10px;
  row-gap: 5px;
}
.latmpoc-span2 {
  grid-column: span 2;
}
.latmpoc-span5 {
  grid-column: span 5;
}
.latmpoc-grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
}

.latmpocTextInput {
  font-size: 8pt;
  font-family: '"Arial", sans-serif';
}

/* .inputFocused {
    border: solid;
    border-width: thin;
    width: 165px;
}
.inputNotFocused {
    margin: 1px;
} */

.latmpocFieldError {
  background-color: #ff6060 !important;
}
.latmpocFieldError:-webkit-autofill {
  background-color: #ff6060 !important;
}
.latmpocFieldFocus {
  border-bottom: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image-slice: 1;
}
.latmpocFieldFocus:-webkit-autofill {
  border-bottom: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image-slice: 1;
}

.latmpocMarginLeft {
  margin-left: 10px;
}

.latmpocMarginButton {
  margin-left: 5px;
}

.latmpocTable {
  border-collapse: collapse;
  font-size: 10pt;
  font-family: '"Arial", sans-serif';
}
.latmpocTable thead tr th {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
.latmpocTable th,
.latmpocTable td {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.latmpocTable th,
.latmpocTable td {
  /* Added padding for better layout after collapsing */
  padding: 4px 8px;
}
.latmpocTableHeadPad {
  margin-top: 5px;
}
