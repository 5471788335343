.cna .BODY {
  font-weight: normal;
  font-size: 10pt;
  background-image: url(img/cna_lightback.gif);
  margin: 0px;
  text-transform: none;
  contain-intrinsic-block-size: AUTO 100px;
  color: black;
  font-family: Arial;
}

.cna .A.sort:link {
  color: #ffff00;
  text-decoration: underline;
}

.cna .A.sort:visited {
  color: #ffff00;
  text-decoration: underline;
}

.cna .TD.headerLabel {
  font-size: 9pt;
  color: yellow;
  font-family: Arial;
  height: 20px;
  background-color: #006464;
  text-align: left;
}

/* Style class for line item text */
.cna .TD.headerText {
  font-size: 9pt;
  color: black;
  font-family: Arial;
  height: 20px;
  background-color: #ebffe1;
  text-align: left;
}

.cna .TD.headerText1 {
  font-size: 9pt;
  color: black;
  font-family: Arial;
  height: 40px;
  background-color: #ebffe1;
  text-align: left;
}

.cna .TD.label {
  font-size: 9pt;
  color: yellow;
  font-family: Arial;
  height: 20px;
  background-color: #006464;
  text-align: center;
}

.cna .TD.text {
  font-size: 9pt;
  color: black;
  font-family: Arial;
  height: 20px;
  background-color: #ebffe1;
  text-align: left;
}

.cna .TD.center {
  font-size: 9pt;
  color: black;
  font-family: Arial;
  height: 20px;
  background-color: #ebffe1;
  text-align: center;
}

.cna .TD.number {
  font-size: 9pt;
  color: black;
  font-family: Arial;
  height: 20px;
  background-color: #ebffe1;
  text-align: right;
}

.cna .TD.title {
  font-weight: bold;
  font-size: 18pt;
  color: #000064;
  font-style: italic;
  font-family: Arial;
  height: 35px;
  text-align: left;
}

.cna .TD.next {
  background-color: #006464;
  text-align: right;
}

.cna .TD.previous {
  background-color: #006464;
  text-align: left;
}

.cna .TD.page {
  font-size: 9pt;
  color: yellow;
  font-family: Arial;
  background-color: #006464;
  text-align: center;
}

.cna .menubox {
  visibility: hidden;
  position: absolute;
}

.cna .menubar {
  font: bold 10pt arial;
}

.cna .TD.silverText {
  font-size: 9pt;
  color: dimgray;
  font-family: Arial;
  height: 20px;
  background-color: #ebffe1;
  text-align: left;
}

.cna .TD.silverCenter {
  font-size: 9pt;
  color: dimgray;
  font-family: Arial;
  height: 20px;
  background-color: #ebffe1;
  text-align: center;
}

.cna .TD.silverNumber {
  font-size: 9pt;
  color: dimgray;
  font-family: Arial;
  height: 20px;
  background-color: #ebffe1;
  text-align: right;
}

.cna .TD.redText {
  font-size: 9pt;
  color: red;
  font-family: Arial;
  height: 20px;
  background-color: #ebffe1;
  text-align: left;
}

.cna .TD.redNumber {
  font-size: 9pt;
  color: red;
  font-family: Arial;
  height: 20px;
  background-color: #ebffe1;
  text-align: right;
}

.cna .TD.redCenter {
  font-size: 9pt;
  color: red;
  font-family: Arial;
  height: 20px;
  background-color: #ebffe1;
  text-align: center;
}

.cna .TD.headerLabelR {
  font-size: 9pt;
  color: yellow;
  font-family: Arial;
  height: 20px;
  background-color: #006464;
  text-align: right;
}

.cna .A:link {
  color: blue;
  text-decoration: underline;
}

.cna .A:visited {
  color: blue;
  text-decoration: underline;
}

.cna .span.pagebanner {
  color: blue;
  padding: 2px 4px 2px 4px;
  width: 100%;
  margin-top: 10px;
  display: block;
  border-bottom: none;
}

/* Style class for sortable, sorted headers. These class are set in displaytag.properties if sorting is used. No need to set them at the page level */
.cna .th.sortable,
.cna .th.sorted,
.cna .th.sortable a,
.cna .th.sorted a {
  font-size: 9pt;
  color: yellow;
  font-family: Arial;
  background-color: #006464;
  text-align: center;
  font-weight: normal;
  height: 20px;
}

/* Style class for line item text */
.cna .TD.lineitemText {
  font-size: 9pt;
  color: black;
  font-family: Arial;
  height: 20px;
  background-color: #ebffe1;
  text-align: left;
}

/* Style class for line item number */
.cna .TD.lineitemNumber {
  font-size: 9pt;
  color: black;
  font-family: Arial;
  height: 20px;
  background-color: #ebffe1;
  text-align: right;
}

/* Style class for line item label */
.cna .TD.lineitemLabel {
  font-size: 9pt;
  color: yellow;
  font-family: Arial;
  height: 20px;
  background-color: #006464;
  text-align: center;
}

/* Style class for pagination links */
.cna .span.pagelinks,
.cna .div.pagelinks {
  color: black;
  padding: 2px 4px 2px 4px;
  width: 100%;
  font-size: 9pt;
  font-family: Arial;
  border-top: none;
  margin-bottom: -5px;
  text-align: center;
}

/* Style class for pagination links */
.cna .a.pagination:visited {
  color: blue;
  text-decoration: underline;
}

/* Style class for pagination links */
.cna .a.pagination:active {
  color: blue;
  text-decoration: underline;
}

/* Style class for pagination links */
.cna .a.pagination:hover {
  color: blue;
  text-decoration: underline;
}

.cna .itemFindBtn {
  color: brown;
  font-family: Sans-serif;
  font-size: 6pt;
  font-weight: bold;
  text-align: right;
}

.cna .headerdescript {
  font-family: "Verdana", "Arial", "Helvetica", "sans-serif";
  font-size: 9px;
  color: #666666;
  font-weight: bold;
}

.cna .section {
  font-weight: bold;
  font-size: 11px;
  color: #7f7f7f;
  line-height: 13px;
  font-family: "Arial", "Helvetica", "sans-serif";
}

/* Hide the dialog title bar. Putting this directly in the CSS file did not work for all browsers */
.cna .uiDialogTitlebar {
  display: none;
}

.cna .copyWrite {
  text-align: -webkit-center;
  display: block;
}
