.s21csamyca {
  font-size: small;
  font-family: sans-serif;
}

.s21csamyca .error {
  color: red;
}

.s21csamyca .inputFocused {
  border: solid;
  border-width: thin;
  width: 165px;
}
.s21csamyca .inputNotFocused {
  margin: 1px;
}

.s21csamyca .expDate {
  background-color: #ffff00;
}

.s21csamyca .divTop {
  height: 100%;
  width: 100%;
  margin: 0px;
  display: flex !important;
  justify-content: flex-start;
}
