@charset "UTF-8";
.s21csaascc .td,
.s21csaascc .outputText {
  font-family: "OpticSansBook", sans-serif;
  font-size: 13px;
  color: #000000;
  line-height: 18px;
}

.s21csaascc .lpad {
  padding-left: 3px !important;
  /* margin-left: 3px !important; */
}

.s21csaascc .input[type="text"],
.s21csaascc .select {
  font-size: 13px;
  margin: 0px 5px;
  font-family: "OpticSansBook", sans-serif;
  background: #ffff00;
  line-height: 18px;
  padding: 3px 3px 3px 3px;
  border-radius: 0px;
  color: #555;
}

.s21csaascc .input-field-invalid {
  border: 1px solid red;
}

.s21csaascc .inputFieldValid {
  border: 0px solid #cccccc;
}

.s21csaascc .errorMessage {
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  margin: 0px;
  padding: 0px 0px 0px 0px;
  font-family: "OpticSansBook", sans-serif;
  color: red;
}

.s21csaascc .creditcard {
  width: 10em;
}

.s21csaascc .expiration {
  width: 5em;
}
