.s21csa {
  font-family: "Arial", sans-serif;
  font-size: 8pt;
}

.s21csa .textInput {
  font-size: 8pt;
  margin: 0px;
  padding: 0px;
  border: solid;
  border-width: thin;
  font-family: "Arial", sans-serif;
}

.s21csa .inputFocused {
  border: solid;
  border-width: thin;
  width: 165px;
}
.s21csa .inputNotFocused {
  margin: 1px;
}

.s21csa .fieldError {
  background-color: #ff6060 !important;
}
.s21csa .fieldError:-webkit-autofill {
  background-color: #ff6060 !important;
}

.s21csa .fieldOK {
  background-color: white;
}

.s21csa {
  font-family: "Arial", sans-serif;
  font-size: 8pt;
}

.s21csaDivTop {
  height: 100%;
  width: 100%;
  margin: 0px;
  display: flex !important;
  justify-content: flex-start;
}
