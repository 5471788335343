.cnaBODY {
  font-weight: normal;
  font-size: 10pt;
  background-image: url(img/cna_lightback.gif);
  margin: 0px;
  text-transform: none;
  contain-intrinsic-block-size: AUTO 100px;
  color: black;
  font-family: Arial;
}
